<template>
    <div class="custom-table-wrapper" ref="tableWrapper">
      <table class="custom-table">
        <thead>
          <tr>
            <!-- 动态渲染列 -->
            <th v-for="(col, index) in columns" :key="index" :style="getColumnStyle(col)">
              {{ col.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- 动态渲染数据行 -->
          <tr v-for="(item, index) in planChild" :key="item.id" @click="handleRowClick(item, index)">
            <td v-for="(col, colIndex) in columns" :key="colIndex">
              {{ item[col.prop] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, defineProps, useSlots, defineEmits } from 'vue';
  
  // 接收父组件传入的数据
  const props = defineProps({
    planChild: {
      type: Array,
      required: true
    }
  });

  console.log(props);
  
  // 获取插槽内容
  const slots = useSlots();
  
  // 获取列配置
  const columns = ref([]);
  
  // 计算列样式
  const getColumnStyle = (col) => {
    return col.width ? `width: ${col.width}px` : 'width: auto';
  };
  
  // 行点击处理
  const emit = defineEmits([]);
  const handleRowClick = (item, index) => {
    emit('row-click', item, index);
  };
  
  // 解析插槽内容
  onMounted(() => {
    // 获取插槽中的所有列配置，并保存到 columns 数组
    columns.value = slots.default ? slots.default().map(slot => slot.props) : [];
  });
  </script>
  
  <style scoped>
  .custom-table-wrapper {
    overflow-x: auto;
    max-width: 100%;
  }
  
  .custom-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .custom-table th,
  .custom-table td {
    padding: 12px 20px;
    font-size: 14px;
    text-overflow: clip;
    white-space: normal;
    word-wrap: break-word;
  }
  
  .custom-table th {
    background-color: #f9fafb;
    color: #909399;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid #ddd;
  }
  
  .custom-table td {
    background-color: #fff;
    color: #666;
  }
  
  .custom-table tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .custom-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table tr:not(:last-child) td {
    border-bottom: 1px solid #e0e0e0;
  }
  
  .custom-table td:first-child,
  .custom-table th:first-child {
    border-left: none;
  }
  
  .custom-table td:last-child,
  .custom-table th:last-child {
    border-right: none;
  }
  </style>
  