<!-- DouTableItem.vue -->
<template>
    <template></template> <!-- 这个组件不渲染任何内容，只用来提供列的配置 -->
</template>

<script setup>
import { defineProps, defineExpose } from 'vue';
// 定义列的配置项
const props = defineProps({
    label: String,
    prop: String,
    width: {
        type: [Number, String],
        default: null
    }
});

// 传递列配置给父组件
defineExpose({
    label: props.label,
    prop: props.prop,
    width: props.width
});
</script>