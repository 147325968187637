// formRule.js
export class formRule {
  constructor(formEntity) {
    let tempResult = {};

    //设置可见
    let visibleRule = {};

    this._attr = formEntity.attr;
    this._attr.forEach(m => {
      if (m.required) {
        let tempRule = {};
        let messageWord = "输入";
        switch (m.type) {
          case "time":
          case "date": {
            messageWord = "选择";
          } break;
        }
        tempRule={ required: true, message: "请" + messageWord + m.display, trigger: "blur" };
        tempResult[m.name] = tempRule;
      }
      visibleRule[m.name] = true;
    });
    this._requiredRule = tempResult;
    this._visibleRule = visibleRule;
  }

  //获取表单必填项规则
  getRequiredRule() {
    return this._requiredRule;
  }

  //设置必填规则
  setFieldRequired(name, required) {
    if (this._requiredRule[name] != null) {
      if (required == false) {
        delete this._requiredRule[name];
      }
    } else {
      if (required == true) {
        let attrObjs = this._attr.filter(m => m.name == name);
        if (attrObjs.length > 0) {
          let attrObj = attrObjs[0];
          let tempRule = {};
          let messageWord = "输入";
          switch (attrObj.type) {
            case "time":
            case "date": {
              messageWord = "选择";
            } break;
          }
          tempRule={ required: true, message: "请" + messageWord + attrObj.display, trigger: "blur" };
          this._requiredRule[attrObj.name] = tempRule;
        }
      }
    }
  }

  //获取显示规则
  getVisibleRule() {
    return this._visibleRule;
  }

  //设置显示规则
  setFieldVisible(name, visible) {
    this._visibleRule[name]=visible;
  }
}