<template>
  <div>
    <span class="dou-badge">目标执行表单</span>
    <el-form :model="form" label-width="80px" ref="formRef">
      <!-- 日期 -->
      <el-form-item label="日期" prop="Date" :rules="rules.Date">
        <el-date-picker v-model="form.Date" type="date" placeholder="请选择日期" style="width: 100%" />
      </el-form-item>

      <!-- 全天开关 -->
      <el-form-item label="是否全天">
        <el-switch v-model="form.IsAllDay" @change="toggleAllDay"></el-switch>
      </el-form-item>

      <!-- 开始时刻 -->
      <el-form-item label="开始时刻" prop="StartTime" v-show="!form.IsAllDay" :rules="rules.StartTime">
        <el-time-picker v-model="form.StartTime" placeholder="请选择开始时间" :format="'HH:mm'" style="width: 100%" />
      </el-form-item>

      <!-- 结束时刻 -->
      <el-form-item label="结束时刻" prop="EndTime" v-show="!form.IsAllDay" :rules="rules.EndTime">
        <el-time-picker v-model="form.EndTime" placeholder="请选择结束时间" :format="'HH:mm'" style="width: 100%" />
      </el-form-item>

      <el-form-item label="目标" prop="PlanId">
        <el-select v-model="form.GoalId" filterable placeholder="请输入或选择" clearable>
          <el-option v-for="item in goalSearchText" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>

      <!-- 内容 -->
      <el-form-item label="内容" prop="Content">
        <el-input type="textarea" v-model="form.Content" :placeholder="'请输入内容'" rows="3" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
        <el-button @click="closeWindow">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps } from "vue";
import { useRoute } from 'vue-router';
import { ElMessage } from "element-plus";
import api from "../../../api/api";
import { computed } from 'vue';
import { useStore } from 'vuex';
import { formRule } from '@/utils/formRule';
import dayjs from 'dayjs';

// 接收父组件传入的 props
const props = defineProps({
  id: {
    type: String
  }
});

//全局对象引入
const store = useStore();

//路由声明
const route = useRoute();

//获取对象属性
const douTable = computed(() => store.state.user.douTable);
let tempEntity = {};
const formEntity = douTable.value.goalexecution;

//初始化属性
formEntity.attr.forEach(element => {
  tempEntity[element];
});
const form = reactive(tempEntity);
//初始化属性（包括临时属性
form.IsAllDay = false;

const formRuleObj = reactive(new formRule(formEntity));
const rules = formRuleObj.getRequiredRule();

// 表单引用
const formRef = ref(null);

// 全天切换逻辑
const toggleAllDay = () => {
  if (form.IsAllDay) {
    formRuleObj.setFieldRequired("StartTime", false);
    formRuleObj.setFieldVisible("StartTime", false);
    formRuleObj.setFieldRequired("EndTime", false);
    formRuleObj.setFieldVisible("EndTime", false);
  } else {
    formRuleObj.setFieldRequired("StartTime", true);
    formRuleObj.setFieldRequired("EndTime", true);
    formRuleObj.setFieldVisible("StartTime", true);
    formRuleObj.setFieldVisible("EndTime", true);
  }
};

let goalSearchText = reactive([]);
//初始化下计划下拉框
const initSearchText = async () => {
  try {
    const response = await api.GetGoalSearchText();
    goalSearchText.splice(0, goalSearchText.length, ...response);
  } catch (error) {
    ElMessage.error("数据加载失败！");
  }
};

// 初始化数据
const initData = async () => {
  if (props.id) {
    try {
      const response = await api.GetGenericDataById("GoalExecution", props.id);
      Object.assign(form, response);
    } catch (error) {
      ElMessage.error("数据加载失败！");
    }
  } else {
    form.Date = route.query.date;
  }
};

// 提交表单
const submitForm = () => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      //根据可用元素确认表单值
      let visibleData = formRuleObj.getVisibleRule();
      const formData = { ...form };
      Object.keys(formData).forEach(m => {
        const tempAttr = visibleData[m];
        if (tempAttr == null || tempAttr == false) {
          delete formData[m];
        } else {
          let attrObj = formEntity.attr.find(n => n.name == m);
          switch (attrObj.type) {
            case "time": {
              formData[m] = formatTime(formData[m]);
            } break;
          }
        }
      });

      //保存
      try {
        // 保存数据
        if (props.id) {
          formData.Id = formData.id;
        }
        formData.Date = dayjs(formData.Date).format('YYYY-MM-DD');
        await api.SaveGenericData("GoalExecution", formData);
        ElMessage.success("保存成功！");
        window.history.go(-1);
      } catch (error) {
        ElMessage.error("保存失败！");
      }
    } else {
      ElMessage.warning("请完善表单！");
    }
  });
};

// 时间格式化工具
const formatTime = (time) => {
  if (!time) return null;
  const hours = String(time.getHours()).padStart(2, "0");
  const minutes = String(time.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}:00`;
};

// 关闭窗口
const closeWindow = () => {
  window.history.go(-1);
};

// 初始化加载
onMounted(async () => {
  await initSearchText();
  initData();
});
</script>

<style scoped>
.dou-badge {
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
}

.dialog-footer {
  margin-top: 20px;
  text-align: right;
}
</style>