<template>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">功能列表</span>
            <div class="d-tab d-center">
                <el-button type="primary" @click="saveUrl">新增网址</el-button>
            </div>
        </el-col>
    </el-row>
    <el-row>
        <el-col :col="24">
            <span class="dou-badge">网址列表</span>
            <el-table :data="objChild" stripe style="width: 100%">
                <el-table-column fixed="left" label="" min-width="50">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click.prevent="showRowInfo(scope.$index)">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M12 22C6.47715 22 2 17.5228 2 12 2 6.47715 6.47715 2 12 2 17.5228 2 22 6.47715 22 12 22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12 20 7.58172 16.4183 4 12 4 7.58172 4 4 7.58172 4 12 4 16.4183 7.58172 20 12 20ZM13 10.5V15H14V17H10V15H11V12.5H10V10.5H13ZM13.5 8C13.5 8.82843 12.8284 9.5 12 9.5 11.1716 9.5 10.5 8.82843 10.5 8 10.5 7.17157 11.1716 6.5 12 6.5 12.8284 6.5 13.5 7.17157 13.5 8Z">
                                </path>
                            </svg>
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column fixed="left" label="" min-width="50">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click.prevent="copyHref(scope.$index)">
                            <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M13.0607 8.11097L14.4749 9.52518C17.2086 12.2589 17.2086 16.691 14.4749 19.4247L14.1214 19.7782C11.3877 22.5119 6.95555 22.5119 4.22188 19.7782C1.48821 17.0446 1.48821 12.6124 4.22188 9.87874L5.6361 11.293C3.68348 13.2456 3.68348 16.4114 5.6361 18.364C7.58872 20.3166 10.7545 20.3166 12.7072 18.364L13.0607 18.0105C15.0133 16.0578 15.0133 12.892 13.0607 10.9394L11.6465 9.52518L13.0607 8.11097ZM19.7782 14.1214L18.364 12.7072C20.3166 10.7545 20.3166 7.58872 18.364 5.6361C16.4114 3.68348 13.2456 3.68348 11.293 5.6361L10.9394 5.98965C8.98678 7.94227 8.98678 11.1081 10.9394 13.0607L12.3536 14.4749L10.9394 15.8891L9.52518 14.4749C6.79151 11.7413 6.79151 7.30911 9.52518 4.57544L9.87874 4.22188C12.6124 1.48821 17.0446 1.48821 19.7782 4.22188C22.5119 6.95555 22.5119 11.3877 19.7782 14.1214Z">
                                </path>
                            </svg>
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column width="100" prop="name" label="名称" />
                <el-table-column width="200" prop="url" label="网址" />
            </el-table>
        </el-col>
    </el-row>
</template>
<script setup>
import { ref, onMounted, defineProps, watch } from 'vue';
const props = defineProps(['entityName', "id"]);
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import api from '../../../api/api.js';
const router = useRouter();
const store = useStore();
const objChild = ref([]);//子列表
onMounted(() => {
    pageInit();
})
watch(() => props.id, () => {
    pageInit();
});
const pageInit = async () => {
    let tempExtData = await api.GetBookMarkExt(props.id);
    objChild.value = tempExtData.child;
}
const saveUrl = () => {
    router.push('/ps/bookmarkdetail/0?pid=' + props.id);
}
const showRowInfo = (e) => {
    let tempId = objChild.value[e].id;
    router.push('/pi/bookmarkdetail/' + tempId);
    //切换标签页
    store.commit('generic/SET_CHILDUPDATETAB', true);
}
const copyHref = async (e) => {
    var text = objChild.value[e].url;
    try {
        // 首先检查浏览器是否支持 Clipboard API
        if (navigator.clipboard && window.isSecureContext) {
            // 使用 Clipboard API 复制文本
            await navigator.clipboard.writeText(text);
            ElMessage({
                message: '复制成功！',
                type: 'success',
            })
        } else {
            // 处理不支持 Clipboard API 的情况，使用选中和 document.execCommand 来复制
            const textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.position = 'fixed'; // 防止移动页面
            textarea.style.opacity = '0'; // 隐藏 textarea
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();

            const success = document.execCommand('copy');
            document.body.removeChild(textarea);
            if (success) {
                ElMessage({
                    message: '复制成功！',
                    type: 'success',
                })
            } else {
                ElMessage.error("复制失败，请重试！");
            }
        }
    } catch (err) {
        ElMessage.error("复制失败，请重试！");
    }
}
</script>